import { useEffect, useState } from "react";
import { Banner } from "../../components/Banner/Banner";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { showConfirmationPage } from "../../utils/Constants";
import { canadaCardLinks, languageOptions, sweepStakes, usCardLinks } from "../../utils/dataConstants";
import i18n from "../../i18n";
import { deleteFromSessionStorage, getSessionStorage } from "../../service/SessionStorageService";
import { getFileName, handleBazarVoice, scrollToTop } from "../../utils/utils";
import MissingProdImag from '../../appSVG/sony_missing_image_square.png';
import { Tooltip } from "@mui/material";
import './RegisterConfirmation.css';
import { useUserPage } from '../../sprUtilityHooks/sprlocationHookcontext';
export const RegisterConfirmation = () => {
	const { t } = useTranslation();
	const { locale } = useParams();
	const navigate = useNavigate();
	const currentPage = useUserPage();
	const [registeredProduct, setRegisteredProduct] = useState(null);

	const browserDetectedLocale = i18n?.resolvedLanguage;
	const { country } = languageOptions[browserDetectedLocale];
	const cardLinks = country === 'us' ? usCardLinks : canadaCardLinks;

	useEffect(() => {
		const state = JSON.parse(getSessionStorage(showConfirmationPage))
		if (!state) {
			navigate("/")
		} else {
			scrollToTop('thankYou')
			deleteFromSessionStorage(showConfirmationPage)
		}
		const registeredProductString = getSessionStorage('registeredProduct')
		if (Boolean(registeredProductString)) {
			setRegisteredProduct(JSON.parse(registeredProductString))
		}
		return () => {
			deleteFromSessionStorage('registeredProduct');
			setRegisteredProduct(null)
		}
	}, []);


	const chaneToWinSweepstake = ()=>{
		var event = new CustomEvent("componentInteractions", {
      detail: {
        page: currentPage,
        type: "click win-sweepstakes",
        event: "interactive click",
        label: "Chance To Win Sony Sweepstakes",
      },
    });
		console.log(event)
    document.dispatchEvent(event);
		window.open(cardLinks[0], "_blank")
	}
	return (
		<>
			<Banner title="register" />
			<section id="about" className="about">
				<div className="container">
					<div className="row content form-registration">
						<div className="col-lg-12 d-flex flex-column justify-content-between">
							<div>
								<div id="thankYou">
									<h2>{t('registrationConfirmation.registerSuccess')}</h2>
									<p>
										{t('registrationConfirmation.continueToBuy')}
									</p>
									<div className="d-flex flex-column align-items-center">
										{Boolean(registeredProduct) &&
											<>
												<Tooltip
													title={registeredProduct?.model_description}
													placement="top">
													<h2 className="product-title">
														{getFileName(registeredProduct?.model_description, 'model_name', 25)}
													</h2>
												</Tooltip>
												<div className="bg-block"
													onClick={() => window.open(cardLinks[0], "_blank")}>
													<img className="register-product-image" src={registeredProduct?.image_url || MissingProdImag} alt={registeredProduct?.model_description} />
												</div>
												<button
													className="account mb-2"
													style={{ width: 300 }}
													onClick={() => handleBazarVoice(registeredProduct?.buzaarVoiceLink, locale)}
												>
													{t("myProducts.Write a Review")}
												</button>
											</>}
										<button className="login d-block" style={{ width: 300 }} onClick={() => navigate("/")}>
											{t('common.Continue')}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="margin-field" />
						<div className="pt-5 d-flex justify-content-around">
							<div className="bg-block align-self-end me-md-0 me-2 tilesContainer"
								onClick={() => chaneToWinSweepstake()}>
								<img src={sweepStakes[browserDetectedLocale]['news']} alt="Chance to Win Sony Sweepstakes" />
							</div>
							<div className="bg-block align-self-end ms-md-0 ms-2 tilesContainer"
								onClick={() => window.open(cardLinks[1], "_blank")}>
								<img src={sweepStakes[browserDetectedLocale]['shop']} alt="Shop Sony Products" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
