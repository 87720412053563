// UserPageContext.js
import React, { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

const UserPageContext = createContext();

export const UserPageProvider = ({ children }) => {
  const location = useLocation();
  const url = window.location.href;
  console.log(location.pathname, url);
  const urlParts = url.split("/");
  const hasLocaleSegment = urlParts.length > 3 && urlParts[3].includes("-");

  let currentPage =
    location.pathname == "/"
      ? "home"
      : hasLocaleSegment
      ? urlParts[4]
      : urlParts[3];

  if (currentPage === "registered_products") {
    console.log(currentPage, "currentpage");
    currentPage = "my products";
  }
  if (currentPage === "registration_confirmation") {
    console.log(currentPage, "currentpage");
    currentPage = "registration confirmation";
  }

  useEffect(() => {
    console.log(location, url);
  }, [location]);

  return (
    <UserPageContext.Provider value={currentPage}>
      {children}
    </UserPageContext.Provider>
  );
};

export const useUserPage = () => {
  return useContext(UserPageContext);
};
